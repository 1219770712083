import React from 'react'

function top() {
  return (
    <section id = "top" class="container">
      <div>
        <img src="images/top/new_banner.png" alt="dynamicsbanner"/>
        <div class="title">Dynamics Shield</div>
        <div class="titletext">Providing clients with highly experienced Micrososft D365 consultants and project managers on customer-side.</div>
      </div>
    </section>
  )
}

export default top
