import React from 'react'

function contacttop() {
  return (
    <section id = "contact" className="container">
      <div className="row">
        
            <div id = "contactcol" class="column">
                <h1>Contact Us</h1>
                <h4>
                We invite you to connect with us. As industry leaders,
                we thrive on forging valuable partnerships and
                delivering exceptional solutions.
                <br/>
                <br/>

                Your inquiries are of utmost importance to us. We
                welcome the opportunity to assist you, answer your
                questions, and explore how we can best serve your
                needs.
                <br/>
                <br/>

                </h4>

                <h5>
                <i>
                Dynamics Shield is a Trademark of TradeInvest Holdings Ltd. CRN 119049
                </i>
                <br/>
                <br/>

                </h5>

                         
            </div>
            <div id = "contactcol" class="column">
                <img src="images/x-icon/1024.png" class="contactimage" alt="contactimage" />
            </div>  
      </div> 
        
    </section>
  )
}

export default contacttop
