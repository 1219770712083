import React from 'react';

function header() {
  return (
    <header>
        <div className="container">
          
          <div className="logo">
            <a
              href="./" 
              target="_self"
              rel="noopener noreferrer"
            >  
              <img src="images/header/logo_grey_text.png" class="logo1" alt="dslogo1" height="100"/>
              <img src="images/header/logo_shield_only.png" class="logo2" alt="dslogo2" height="87"/>
            </a>
          </div>
          <div className="menu">
            {/* <a href="/consultancy">D365 Consultancy Services</a> */}
            {/* <a href="/casestudies">Case Studies</a> */}
            {/* <a href="/casestudies">Vacancies</a> */}
            <a href="/contactus">Contact Us</a>
          </div>
        </div>
    </header>
  )
}

export default header
