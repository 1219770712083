function errormessage() {
    return (
      <section id = "errormessage" className="container">
        <div className="errorpage">
                
            <h2>Oops! Something went wrong!</h2>

            <a
              href="./" 
              target="_self"
              rel="noopener noreferrer"
            >
                <img src="images/error/logo.png" alt="dynamics-shield-logo"/>
            </a>
  
        </div>
      </section>
    )
  }
  
  export default errormessage
  