import React from 'react'
//components
//import Layout from "../components/layout/layout";
import ContactHeader from "../components/contact/contactheader";
import ContactTop from "../components/contact/contacttop";
// import Form from "../components/contact/form";
import ContactUs from "../components/contact/contactus";
import PrivacyStatement from "../components/contact/privacystatement";
import Footer from "../components/footer";



function Contact() {
  return (
    <>
      <ContactHeader/>

      <main>
        <ContactTop/>

        <ContactUs/>
        {/* <Form/> */}

        <PrivacyStatement/>
      </main>

      <Footer/>
    </>
  )
}

export default Contact;