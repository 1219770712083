import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

//pages
import Home from './pages/Home';
import Contact from './pages/Contact';
import Error from './pages/Error';

const AppRoutes = () => {
    return (
        <Router>
            <Routes>
                <Route exact path='/' element={<Home />} />
                <Route path='/contactus' element={<Contact />} />
                <Route path='*' element={<Error />} />
            </Routes>
        </Router>
    );
};

export default AppRoutes;