import React from 'react'

function quote() {
  return (
    <section id = "quote" className="container">
      <div className="row">
        <h1>Why Dynamics Shield?</h1>
        <div id = "quotecol" class="column">
          <h4>
              Are you ready to experience a seamless and hassle-
              free implementation of your new D365 roll-out? Or do you 
              need support with your current implementation?
              Then look no further as we're the consultants
              for you!
              <br/>
              <br/>

              With a combined experience of over 12 years in Microsoft D365
              our team has both partner and client-side expertise in 
              consultancy and project management to help you manage 
              and mitigate any potential pitfalls on your D365 project.
              <br/>
          </h4>         
        </div>
        <div id = "quotecol" class="column">
          <img src="images/quote/side-image.png" class="quoteimage" alt="quoteimage" />
        </div>    
          
      <a href="/contactus" className="quote-btn btn">
        Contact us for a free quote
      </a>
 
      </div>
    </section>
  )
}

export default quote
