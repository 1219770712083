import React from 'react';

function contactheader() {
  return (
    <header>
        <div className="container">
          
          <div className="logo">
            <a
              href="./" 
              target="_self"
              rel="noopener noreferrer"
            >  
              <img src="images/header/logo_grey_text.png" class="logo1" alt="dslogo1" height="100"/>
              <img src="images/header/logo_shield_only.png" class="logo2" alt="dslogo2" height="87"/>
            </a>
          </div>
          <div className="menu">
          <a href="./" 
              target="_self"
              rel="noopener noreferrer">Home</a>
          </div>
        </div>
    </header>
  )
}

export default contactheader
