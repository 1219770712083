import React from 'react'

//components
import Layout from "../components/layout/layout";
import Top from "../components/top";
import Quote from "../components/quote";
import Highlights from "../components/highlights";

function Home() {
  return (
    <Layout>
      <Top/>
      <Quote/>
      <Highlights/>
    </Layout>
  )
}

export default Home
