import React from 'react'

function privacystatement() {
  return (
    <section id = "privacystatement" className="container">
      <div className="privstatement">
        <h4>
            Note: We respect your privacy and will not share your information with any third parties.
            Thank you for reaching out to us. We look forward to connecting with you soon!
        </h4>
      </div>
    </section>
  )
}

export default privacystatement