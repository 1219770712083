import React from 'react'

function highlights() {
  return (
    <section id = "highlights" className="container">
      <div id="highcol" class="column">
        <div id="rectangle">
          <h2>Protection and Guideince</h2>
            <img src="images/highlights/highlight_1.png" class="highlightsimage" alt="highlightsimage"/>
          <h3>At Dynamics Shield we safeguard your interest
              by providing you with seasoned client side
              consultants and project managers, liasing
              with your D365 partner for you, ensuring 
              milestones are reached and avoiding costly 
              delays.
          </h3>
        </div>
      </div>
      <div id="highcol" class="column">
        <div id="rectangle">
          <h2>Reliability & Peace of mind</h2>
            <img src="images/highlights/highlight_2.png" class="highlightsimage" alt="highlightsimage"/>
          <h3>Our seasoned profesionalls will run the
              project and consolidate all the information
              you require to keep you abreast of project 
              progress and help you make the right desitions
              on your digital transformation journey.
          </h3>
        </div>
      </div>
      <div id="highcol" class="column">
        <div id="rectangle">
          <h2>Experience & Transparency</h2>
            <img src="images/highlights/highlight_3.png" class="highlightsimage" alt="highlightsimage"/>
          <h3>With the extensive experience our consultants 
              and project managers have, you can be sure that
              any issues are pre-empted and tackled before 
              they even happen. All information is shared with you,
              enabling a greater relationship of trust and peace 
              of mind.
          </h3>
        </div>
      </div>
    </section>
  )
}

export default highlights