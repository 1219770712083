import React from 'react'

//components
import Layout from "../components/layout/layout";
import Header from "../components/header";
import ErrorMessage from "../components/errormessage";
//import Footer from "../components/footer";
//import ErrorFooter from "../components/errorfooter";

function Error() {
  return (
    <Layout>
        <Header />
        <ErrorMessage />
    </Layout>
  )
}

export default Error
