import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

export const ContactUs = () => {
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();

      emailjs.sendForm('service_5uo4n2f', 'template_xpggg38', form.current, 'VCHzZVVFjPgYDtsy0')
        .then((result) => {
            console.log(result.text);
            alert("We have received your enquiry! We will get back to you shortly!");
        }, (error) => {
            console.log(error.text);
            alert("Sorry there was an error. Please try again later.");
        });
        e.target.reset()
    };


    return (
      <section id = "contactform" className="container">
          <form ref={form} onSubmit={sendEmail} id="contactForm">
              <input type="text" name="name" placeholder="Name" required />
              <input type="text" name="surname" placeholder="Surname" required />
              <input type="text" name="company" placeholder="Company Name" required />
              <input type="email" name="email" placeholder="Email" required />
              <input type="tel" name="phone" placeholder="Phone Number" />
              <textarea name="message" placeholder="Message" required></textarea>
              <button type="submit">Submit</button>
          </form>



      </section>

    )
}

export default ContactUs;


/* 

// Function to validate email addresses
function isValidEmail(email) {
  // Define the JS Regex pattern for a valid email address
  var emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
  
  // Test the email against the pattern and return the result (true or false)
  return emailRegex.test(email);
}

// Function to validate phone numbers
function isValidPhoneNumber(phone) {
  // Define the JS Regex pattern for a valid 10-digit phone number
  var phoneRegex = /^\d{10}$/;
  
  // Test the phone number against the pattern and return the result (true or false)
  return phoneRegex.test(phone);
}

// Function to validate the contact form
function validateForm() {
  // Get the values entered in the form fields
  var name = document.forms["contactForm"]["name"].value;
  var surname = document.forms["contactForm"]["surname"].value;
  var company = document.forms["contactForm"]["company"].value;
  var email = document.forms["contactForm"]["email"].value;
  var phone = document.forms["contactForm"]["phone"].value;
  var message = document.forms["contactForm"]["message"].value;

  // Check if the required fields (name, email, and message) are empty
  // If any of them are empty, return false to prevent form submission
  if (!name || !surname || !company || !email || !message) {
    return  false;
  }

  // Check if the email is valid using the isValidEmail function
  // If the phone field is not empty, also check if it is valid using the isValidPhoneNumber function
  // If either the email or the phone number is invalid, return false to prevent form submission
  if (!isValidEmail(email) || (phone && !isValidPhoneNumber(phone))) {
    return false;
  }

  // If all the validations pass, return true to allow form submission
  return true;
}

// Function to display an error message on the web page
function displayError(message) {
  // Get the HTML element with the "error" ID to display the error message
  var errorElement = document.getElementById("error");
  
  // Set the innerHTML of the error element to the provided error message
  errorElement.innerHTML = message;
  
  // Change the display style of the error element to "block" to make it visible
  errorElement.style.display = "block";
}
 */

//function form() {
/* const Contact = () => {

  const form = useRef();

  const sendEmail = () => {}; */
//export default form