import React from 'react';

function footer() {
  return (
      <footer>
          <div className="container">
          
          <p>© Dynamics Shield Ltd. All rights reserved.</p>

          </div>
      </footer>
  )
}

export default footer
